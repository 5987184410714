/*! test */

$portalColor: #004A3B;
$portalLightColor: #00973b;
$portalTraspColor: #0b5d50;
$portalDarkColor: #004A3B;
$portalLighter: #e5f2ed;
$textColor: #434544;
$body-color: $textColor;
$white: #ffffff;
$black: #000000;
$yellow: #ffee00;
$grey-text: #606060;
$bg-grey: #F0F0F0;
$head-grey: #585a59;
$bg-green: #e4ece7;
$grey1: #cccccc;
$grey2: #dddddd;
$grey3: #f6f6f6;
$grey4: #f4f4f4;
$grey5: #ababab;
$grey6: #efefef;
$grey7: #b5b5b5;
$grey9: #dadada;
$grey10: #999;
$red: #dd0101;
$blue: #2b629c;
$grey-doknr: #686a69;

$color-style1: $bg-grey;


$vertraulich: $red;
$in-arbeit: $blue;
$border: #abc3bf;
$border1: $grey2;
$border2: #DCE0DE;
$input-border-radius: 2px;

$font-family-base: "Calibre LH Regular";

$headings-font-family: "Calibre LH Headline";

$secondary: #FFDC00;
$primary: $portalColor !default;
$primary-wcag-small: #004A3B;

$navbar-light-active-color: $primary;
$navbar-light-color: $primary;
$navbar-light-hover-color: $primary;
$navbar-toggler-focus-width: 0;
$font-size-base: 1.1rem !default;
$line-height-base: 1.5;
$font-size-icon-button: 0.95rem !default;
$font-base-path: "/src_project/fonts/lh_4/";
$font-base-path-portal: "/src_project/fonts/";
$font-base-path-calibre: "/src_project/fonts/calibre-lh/";
$headings-font-weight: 400;
$navbar-nav-link-padding-x: 1rem;
/* Change-April 0.5rem*/

$h1-font-size: 1.4rem !default;
$h2-font-size: 1.25rem !default;
$h3-font-size: $font-size-base !default;
// Form

$form-group-margin-bottom: 0.75rem !default;

$input-btn-line-height: 1.75;
$btn-border-radius: 2px;
$btn-padding-x: 1.875rem;
$btn-font-size: 1.2rem;
$card-border-color: $border1;
$card-border-radius: 0;
$card-cap-bg: #f7f7f7;
$card-spacer-x: 1.875rem;

$modal-md: 600px;
$modal-content-border-radius: 0;
$modal-title-line-height: 1;
$modal-header-border-width: 0;
$modal-header-padding: 1.3rem 1.3rem 0.5rem 1.3rem;
$modal-inner-padding: 1.3rem;


$input-focus-box-shadow: none;
$input-focus-border-color: $portalLightColor;
$input-border-color: #878A88;
$form-input-border-radius: 2px;

$content-container-x-margin: 0.5rem !default;
$content-container-y-margin: 0 !default;
$paragraph-margin-bottom: 0.5rem;
/*Header*/
/*Header height für anker link positionierung*/
$header-height-xs: 121px;
$header-height-up-sm: 121px;
$header-height-up-md: 121px;
$header-height-up-lg: 163px;
$header-height-up-xl: 189px;
$navbar-dark-color: #F0FFF6;
$header-margin: 1.875rem;


$dropdown-link-hover-bg: transparent;
$dropdown-link-color: $white;
$dropdown-link-hover-color: lighten($portalColor, 70%);
$dropdown-min-width: 11em;
$dropdown-border-radius: 0;
$dropdown-box-shadow: none;
$dropdown-border-width: 0px;
$dropdown-bg: $primary;
$dropdown-font-size: 1.15rem;
$dropdown-item-padding-y: 0.85rem;
/*Form*/
$form-check-input-width: 1.05em !default;
$form-check-input-height: 1.05em !default;
$form-check-input-checked-bg-color: white !default;
$form-check-input-checked-color: $primary !default;
$form-check-input-checked-border-color: $primary !default;
$form-check-input-border-radius: 0px !default;
$form-check-margin-bottom: 0.5rem !default;
$padding-input-x: 0.375rem;
$padding-input-y: 0.75rem;

/*Pagination*/
$footer-color: #434544;
/*Pagination*/
$pagination-border-active: black;
$pagination-bg-active: white;
$pagination-border-active-hover: #eaeaea;
$pagination-bg-active-hover: white;
$pagination-active-color: $primary-wcag-small;
$pagination-active-color-hover: $primary-wcag-small;

$pagination-color: #646665;
$pagination-color-hover: $primary-wcag-small;
$pagination-border: #ccc;
$pagination-bg: white;
$pagination-border-hover: black;
$pagination-bg-hover: white;
$pagination-border-radius: 0;

$pagination-border-width: 0;
$pagination-svg-width: 0.875rem;
$pagination-svg-height: 0.875;
$pagination-width: auto;
$pagination-height: 1.25rem;
$pagination-font-size: $font-size-base;
$pagination-line-height: 1;

$pagination-border-disabled: #ccc;
$pagination-bg-disabled: white;
$pagination-disabled-color: #ccc;

$grid-gutter-width: 2.5rem;


$link-color: #434544;
$link-hover-color: $primary-wcag-small;

//tables
$table-primary-bg: $portalColor;
$table-fontsize-td: $font-size-base;
$table-fontsize-th: $font-size-base;

//Content Carousel Img Height
//$contentCarousel-HeightXS
//$contentCarousel-HeightSM
//$contentCarousel-HeightMD
//$contentCarousel-HeightLG
//$contentCarousel-HeightXL
$contentCarousel-HeightXXL: 430px;
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1250px) !default;
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
	sm: 540px,
	md: 738px,
	lg: 960px,
	xl: 1210px) !default;


// overwrite bootstrap variables here - if any
// $varname: 'var-value';
// necessary imports
@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/utilities";
